#welcomeAbout {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/About/about.png") no-repeat center center;
  background-size: cover;
  color: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.welcome-text {
  font-weight: 800;
  font-family: "Inter";
  font-size: 2rem;
}

.growPic {
  max-width: 20px;
  height: 10px;
}

@media (min-width: 768px) {
  .welcome-text {
    font-size: 3rem;
  }

  .growPic {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767.98px) {
  #welcomeAbout {
    padding: 30px 15px;
  }

  .growPic {
    display: none;
  }

  .welcome-text {
    font-size: 1.5rem;
  }
}
