#plan {
  height: auto;
}

#plan .gradBG {
  background: rgb(61, 24, 211);
  background: linear-gradient(
    180deg,
    rgba(61, 24, 211, 0.72) 0%,
    rgba(255, 255, 255, 1) 47%,
    rgba(61, 24, 211, 0.81) 100%
  );
  border-radius: 20px;
}

#plan img {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px 0 0 20px;
}

#plan .planx {
  border-radius: 0 20px 20px 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

#plan .insidePlan {
  border-radius: 20px;
  background: #ecf0ff;
  position: relative;
}

.insidePlan .bonus {
  background: #6aaeff;
  width: 200px;
  height: 90px;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: end;
  border-radius: 50px 0 0 50px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 2rem;
}

.insidePlan .bonus p,
.insidePlan .bonus span {
  color: white;
}

.insidePlan .bonus p {
  font-weight: 500;
  font-size: 1.5rem;
}

.insidePlan .bonus span {
  font-weight: normal;
  font-size: 1.1rem;
}

.insidePlan span {
  color: #1fcac5;
  margin-right: 1rem;
}

.insidePlan .btn {
  background-color: #1fcac5 !important;
  padding: 0.5rem 2rem;
}

.insidePlan .btn:hover,
.insidePlan .btn:focus {
  background-color: #1fcac5 !important;
  filter: brightness(0.9);
}
