#sysreq {
	margin-top: 6rem;
	background: white;
	padding: 2rem 0;
  }
  
  #sysreq .card {
	border: 3px solid var(--main-color);
	width: 100%;
	max-width: 100%;
  }
  
  #sysreq .imgBg {
	position: relative;
	padding: 15px;
  }
  
  #sysreq .imgBg img {
	background-color: var(--main-color);
	border-radius: 50%;
	width: 70px;
	height: 70px;
	object-fit: cover;
	position: absolute;
	top: -35px;
	left: 50%;
	transform: translateX(-50%);
  }
  
  #sysreq .card-body {
	font-size: 14px;
  }
  
  .sys-req-list {
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .sys-req-list li {
	margin-bottom: 10px;
  }
  
  #fill {
	background: var(--main-color-hover);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
  }
  
  #fill .infoPic {
	width: 60px;
	height: 60px;
  }
  
  #fill blockquote {
	width: 100%;
	max-width: 800px;
	font-size: 14px;
	margin-top: 1rem;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
	#sysreq .card-body {
	  font-size: 12px;
	}
  
	#fill blockquote {
	  font-size: 12px;
	}
  }
  
  @media (max-width: 576px) {
	#sysreq .card-body {
	  font-size: 11px;
	}
  
	#fill {
	  padding: 0.5rem;
	}
  
	#fill .infoPic {
	  width: 50px;
	  height: 50px;
	}
  
	#fill blockquote {
	  font-size: 11px;
	}
  }
  