#welcome_career {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/Career/feedback.png") no-repeat center center;
  background-size: cover;
  color: white;
  height: calc(100vh - 92px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  text-align: center;
}

#welcome_career h1 {
  font-size: 2.5rem; /* Adjusted for responsiveness */
  font-weight: bolder;
  margin-bottom: 1rem;
}

#welcome_career h2 {
  font-size: 1.25rem; /* Adjusted for responsiveness */
  font-weight: lighter;
  margin-bottom: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  #welcome_career {
    height: auto; /* Allow content to flow naturally on smaller screens */
    padding: 50px 15px; /* Add padding to ensure content does not touch the edges */
  }

  #welcome_career h1 {
    font-size: 2rem; /* Smaller font size for smaller screens */
  }

  #welcome_career h2 {
    font-size: 1rem; /* Smaller font size for smaller screens */
  }
}

@media (max-width: 480px) {
  #welcome_career {
    padding: 30px 15px; /* Add more padding for very small screens */
  }

  #welcome_career h1 {
    font-size: 1.5rem; /* Even smaller font size for very small screens */
  }

  #welcome_career h2 {
    font-size: 0.875rem; /* Even smaller font size for very small screens */
  }
}
