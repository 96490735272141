#welcomeAbout {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/About/about.png") no-repeat center center;
  background-size: cover;
  color: white;
  height: calc(100vh - 92px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

#welcomeAbout .container {
  max-width: 1200px; /* Adjust container width */
}

#welcomeAbout h1 {
  margin-bottom: 1.5rem; /* Adjusted margin */
}

#welcomeAbout .btn {
  font-size: clamp(0.9rem, 2vw, 1.1rem); /* Responsive font size */
  padding: 0.5rem 2rem; /* Adjust padding */
}

#welcomeAbout .growPic {
  max-width: 100%;
  height: auto;
  object-fit: cover; /* Ensures image covers its container without distortion */
}

/* Responsive Styles */
@media (max-width: 768px) {
  #welcomeAbout {
    height: auto;
    padding: 2rem 0; /* Adjust padding for smaller screens */
  }

  #welcomeAbout .container {
    padding: 0 1rem; /* Reduce container padding */
  }

  #welcomeAbout h1 {
    font-size: clamp(1.2rem, 5vw, 2rem); /* Responsive font size */
  }

  #welcomeAbout .btn {
    font-size: clamp(0.8rem, 3vw, 1rem); /* Responsive font size */
    padding: 0.4rem 1.5rem; /* Adjust padding */
  }
}

@media (max-width: 576px) {
  #welcomeAbout .row {
    flex-direction: column;
  }

  #welcomeAbout .col-lg-6, 
  #welcomeAbout .col-md-8 {
    text-align: center;
    margin-bottom: 2rem; /* Spacing between text and image on small screens */
  }

  #welcomeAbout .btn {
    font-size: clamp(0.7rem, 4vw, 0.9rem); /* Responsive font size */
    padding: 0.3rem 1rem; /* Adjust padding */
  }
}
