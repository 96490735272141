#lookingsection .card {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 16rem !important;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

#lookingsection .card img {
  height: 90px;
  width: 90px;
}
