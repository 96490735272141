.navbar {
  background-color: #f8f9fa; /* Light background color */
  padding: 0; /* Remove extra padding */
  height: 132px; /* Adjust height to match the logo height */
  display: flex;
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0; /* Adjust padding for alignment */
}

.navbar-brand img {
  height: 100px; /* Reduce the logo height */
  width: 250px; /* Maintain aspect ratio */
  margin-right: 20px; /* Adjust space to the right of the logo */
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28150, 150, 150, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-collapse {
  background-color: #f8f9fa; /* Ensure background color extends to the collapsed menu */
}

.navbar-nav .nav-link {
  color: #fff; /* White text color for better contrast */
  margin: 0 1rem;
  transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover {
  color: #333; /* Darker text color on hover */
}

.navbar-nav .btn {
  background-color: #f8f9fa; /* Primary button color */
  color: #fff; /* White text color */
  border-radius: 50px; /* Rounded button */
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease-in-out;
}

.navbar-nav .btn:hover {
  background-color: #003f7f; /* Darker shade on hover */
}

@media (max-width: 768px) {
  .navbar {
    background-color: #f8f9fa; /* Maintain the background color on mobile */
  }

  .navbar-toggler {
    background-color: #f8f9fa; /* Ensure the toggler button inherits the background */
  }

  .navbar-collapse {
    background-color: #f8f9fa; /* Background color for the expanded menu */
  }

  .navbar-nav .nav-link {
    display: block;
    text-align: center;
    margin: 0.5rem 0;
    color: #fff; /* White text for visibility */
  }

  .navbar-nav .btn {
    display: block;
    margin: 0.5rem auto;
  }
}
