#grow1 {
	background-color: var(--main-color);
  }
  
  #grow1 .bg-primary {
	border-radius: 5px;
  }
  
  #grow1 .big-text {
	font-size: 32px;
  }
  
  #grow1 .lil-text {
	text-align: justify;
	font-size: 16px;
  }
  
  #grow1 .lil-text span {
	color: var(--main-light);
	font-weight: bold;
  }
  
  #grow1 .growPic {
	height: auto;
	width: 100%;
	max-width: 352px;
	object-fit: cover;
	margin-bottom: 20px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 992px) {
	#grow1 .big-text {
	  font-size: 28px;
	}
  
	#grow1 .lil-text {
	  font-size: 14px;
	}
  
	#grow1 .growPic {
	  max-width: 300px;
	}
  }
  
  @media (max-width: 768px) {
	#grow1 .big-text {
	  font-size: 24px;
	}
  
	#grow1 .lil-text {
	  font-size: 13px;
	}
  
	#grow1 .growPic {
	  max-width: 250px;
	}
  }
  
  @media (max-width: 576px) {
	#grow1 .big-text {
	  font-size: 20px;
	}
  
	#grow1 .lil-text {
	  font-size: 12px;
	}
  
	#grow1 .growPic {
	  max-width: 200px;
	}
  }
  