#hero3 {
	position: relative;
	width: 100%;
	min-height: 100vh; /* Ensure at least full viewport height */
	overflow: hidden; /* Hide overflow from absolutely positioned elements */
}

#hero3 .bg-blur {
	position: absolute;
	width: 100%;
	height: 100%;
	background: url("../../assets/StratCall/Hero.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	left: 0;
	filter: blur(5px);
	
}

#hero3 .main {
	position: relative; /* Changed to relative for proper flow */
}

#hero3 .circle1, #hero3 .circle2, #hero3 .circle3, #hero3 .triangle1, #hero3 .triangle2 {
	position: absolute;
}

#hero3 .circle1 {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	background: #ebfa91;
}

#hero3 .circle2 {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	background: #fac391;
}

#hero3 .triangle1, #hero3 .triangle2 {
	width: 166px;
	height: 150px;
	background: transparent;
}

#hero3 .triangle1 {
	background: #65a9fb;
	transform: rotate(42.54deg);
}

#hero3 .triangle2 {
	background: #f865fb;
	transform: rotate(61.73deg);
}

#hero3 .circle3 {
	object-fit: cover;
	border-radius: 50%;
	width: 432px;
	height: 413px;
	background: url("../../assets/StratCall/Call.jpg");
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#hero3 .fields {
	position: absolute;
	z-index: 20;
	background-color: rgba(0, 0, 0, 0.25);
	width: 100%; /* Ensure it spans the full width */
	height: auto; /* Adjust height automatically based on content */
}

.heroForm label {
	color: white;
}

/* Specific positioning for the other elements */
#hero3 .circle1 {
    left: calc(50% - 210px);
    top: calc(50% - 215px);
}

#hero3 .circle2 {
    left: calc(50% - 235px);
    top: calc(50% - 190px);
}

#hero3 .triangle1 {
	left: calc(50% + 60px);
    top: calc(50% + 60px);
}

#hero3 .triangle2 {
	left: calc(50% + 115px);
    top: calc(50% + 50px);
}
