#advantage {
	background: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  #advantage .container {
	max-width: 1200px;
  }
  
  #advantage .card-title {
	font-size: 18px;
	font-weight: 600;
  }
  
  #advantage .card-text {
	font-size: 14px;
  }
  
  @media (max-width: 992px) {
	#advantage .card-title {
	  font-size: 16px;
	}
  
	#advantage .card-text {
	  font-size: 13px;
	}
  }
  
  @media (max-width: 768px) {
	#advantage .card-title {
	  font-size: 14px;
	}
  
	#advantage .card-text {
	  font-size: 12px;
	}
  }
  
  @media (max-width: 576px) {
	#advantage .card-title {
	  font-size: 12px;
	}
  
	#advantage .card-text {
	  font-size: 11px;
	}
  }
  