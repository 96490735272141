#howtoApply {
	background: white;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
  }
  
  #howtoApply .fw-bolder {
	font-size: 1.5rem;
  }
  
  #howtoApply .border {
	display: inline-block;
  }
  
  #howtoApply .mt-5 {
	margin-top: 3rem !important;
  }
  
  #howtoApply .col-md-4 {
	margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
	#howtoApply .col-md-4 {
	  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	#howtoApply .fw-bolder {
	  font-size: 1.25rem;
	}
  
	#howtoApply .mt-5 {
	  margin-top: 2rem !important;
	}
  }
  
  @media (max-width: 576px) {
	#howtoApply .fw-bolder {
	  font-size: 1rem;
	}
  
	#howtoApply .col-md-4 .card-title {
	  font-size: 1rem;
	}
  
	#howtoApply .col-md-4 .card-text {
	  font-size: 0.875rem;
	}
  }
  