#success {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/Home/image 26.png") no-repeat center center;
  background-size: cover;
  color: white;
  height: auto;
  padding: 4rem 2rem; /* Adjusted padding for better spacing */
  text-align: center;
  position: relative;
  overflow: hidden;
}

#success h1 {
  font-size: 1.75rem; /* Smaller font size */
  font-weight: 700;
  margin-bottom: 0.75rem; /* Adjusted margin for spacing */
}

#success h1 span {
  color: #31b6dd; /* Accent color for emphasis */
}

#success p {
  font-weight: lighter;
  font-size: 0.575rem; /* Smaller font size */
  margin: 0 auto;
  max-width: 600px; /* Adjusted maximum width for better text fit */
  line-height: 1.6; /* Increased line height for readability */
  color: #f8f9fa; /* Slightly lighter text color for readability */
}

@media (min-width: 768px) {
  #success h1 {
    font-size: 2rem; /* Slightly larger for medium to larger screens */
  }

  #success p {
    font-size: 1rem; /* Increased font size for larger screens */
    max-width: 700px; /* Adjusted maximum width */
  }
}
