#industries .hero-industry {
	height: 92vh;
	background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(0, 0, 0, 0.8)),
		url("../../../assets/Indus/industryHero.jpg") no-repeat center
			center/cover;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}

#industries .banner {
	background: var(--main-color);
}
