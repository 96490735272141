#who {
  background-color: var(--main-color);
}

#who .who-text {
  font-size: 2rem;
}

#who .lil-text {
  text-align: justify;
  font-size: 1rem;
}

@media (max-width: 768px) {
  #who .who-text {
    font-size: 1.5rem;
  }

  #who .lil-text {
    font-size: 0.875rem;
  }

  .d-flex {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  #who .who-text {
    font-size: 1.25rem;
  }

  #who .lil-text {
    font-size: 0.75rem;
  }

  .px-3 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
