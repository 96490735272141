/* General Styles */
.navbar {
  background-color: #f8f9fa; /* Light background color */
  padding: 0; /* Remove extra padding */
  height: 100px; /* Ensure navbar height matches the logo height */
  display: flex;
  align-items: center; /* Center content vertically */
}

.logo-container {
  overflow: hidden; /* Prevents the navbar from expanding */
  height: 100px; /* Ensure container height matches logo height */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  display: flex;
  align-items: center; /* Center logo vertically */
}

.navbar-logo {
  height: 100px; /* Increase the logo size */
  width: 250px; /* Maintain aspect ratio */
  position: relative;
  padding: 0;
  margin: 0; /* Remove margin */
}

.nav-items {
  text-decoration: none;
}

.nav-items:hover {
  color: var(--main-color) !important;
  text-decoration: none;
}

.navbar-toggler {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.btn {
  background-color: #007bff; /* Primary button color */
  color: #fff; /* White text color */
  border-radius: 50px; /* Rounded button */
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease-in-out;
}

.btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Tablets */
@media (max-width: 768px) {
  .navbar-toggler {
    display: block;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-bg-opacity)
    ) !important;
    width: 100%;
    text-align: center;
    z-index: 10;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-menu a,
  .nav-menu button {
    margin: 1rem 0;
  }
}

/* Smartphones */
@media (max-width: 480px) {
  body {
    font-size: 12px;
  }
}

/* Navbar styles */
.nav-menu.open {
  display: flex;
  flex-direction: column;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  text-align: left;
}

/* Close Button */
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: auto;
    max-height: 90%;
    padding: 10px;
  }
}
