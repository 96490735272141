#services {
  background-color: #f8f9fa; /* Light background for contrast */
  padding: 3rem 0;
}

#services h2 {
  color: #2c3e50; /* Darker, modern text color */
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
}

#services .card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; /* Ensures all cards have the same height */
}

#services .card {
  border: none;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Heavier shadow for depth */
  border-radius: 12px; /* Slightly more rounded corners */
  overflow: hidden; /* To ensure the image does not overflow */
  background-color: #ffffff; /* Card background color */
  transition: transform 0.4s ease, box-shadow 0.4s ease; /* Smooth transition */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content */
  align-items: center;
  padding: 2rem; /* More padding for a spacious feel */
  max-width: 350px;
  width: 100%; /* Ensure full width within the container */
  margin: 1rem;
  min-height: 400px; /* Set a minimum height to ensure uniformity */
  flex: 1; /* Allow cards to grow equally */
}

#services .card:nth-child(odd) {
  background-color: #e3f2fd; /* Light blue for odd cards */
}

#services .card:nth-child(even) {
  background-color: #fff3e0; /* Light orange for even cards */
}

#services .card:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15); /* Increased shadow on hover */
}

#services .card img {
  height: 80px; /* Adjusted size for icons */
  width: 80px; /* Adjusted size for icons */
  margin-bottom: 1.5rem;
  transition: transform 0.4s ease; /* Smooth scaling effect */
}

#services .card img:hover {
  transform: scale(1.1); /* Slight scale up on hover */
}

#services .card-body {
  text-align: center; /* Center text */
  flex-grow: 1; /* Ensures the body takes up available space */
}

#services .card-title {
  font-size: 1.5rem; /* Larger title */
  color: #007bff; /* Primary color for titles */
  margin-bottom: 1rem;
  font-weight: 600; /* Bolder title */
}

#services .card-text {
  font-size: 1rem; /* Standard font size for text */
  color: #34495e; /* Darker gray text for better readability */
  margin-bottom: 2rem; /* More space before the button */
}

#services .btn-text {
  margin-top: 3rem;
  text-align: center;
}

#services .btn-text p {
  max-width: 650px; /* Wider text area */
  margin: 0 auto 2rem; /* Increased margin for better spacing */
  font-size: 1.1rem;
  color: #2c3e50; /* Text color for paragraph */
}

#services .btn-text .btn {
  background-color: #022b57; /* Primary color for button */
  color: #fff; /* White text for button */
  padding: 0.75rem 2rem; /* Padding for button */
  border-radius: 30px; /* More rounded button corners */
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth color transition */
}

#services .btn-text .btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: translateY(-3px); /* Slight lift on hover */
}

/* Media query to ensure cards maintain uniform height and layout on smaller screens */
@media (max-width: 768px) {
  #services .card-container {
    flex-direction: column;
    align-items: center;
  }
}
