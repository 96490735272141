#blog1 .Hero {
	height: 50vh;
	background: linear-gradient(rgb(156, 156, 156), rgb(156, 156, 156)),
		url("../../../assets/Blog/WFHisBetter.png") no-repeat center center /
			cover;
	background-blend-mode: overlay;
}

#blog1 p {
	text-align: justify;
}

#conclusion {
	text-align: justify;
	line-height: 2.3rem;
	font-size: 18px;
}

#blog2 .Hero {
	height: 50vh;
	background: linear-gradient(rgb(156, 156, 156), rgb(156, 156, 156)),
		url("../../../assets/Blog/VA101.png") no-repeat center center / cover;
	background-blend-mode: overlay;
}
#blog3 .Hero {
	height: 50vh;
	background: linear-gradient(rgb(156, 156, 156), rgb(156, 156, 156)),
		url("../../../assets/Blog/SetupWFH.png") no-repeat center center / cover;
	background-blend-mode: overlay;
}

#blog3 ul {
	list-style: none;
}
