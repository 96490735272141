footer {
	background-color: #343a40;
  }
  
  footer h5 {
	margin-bottom: 1rem;
	color: var(--main-light);
  }
  
  footer p {
	margin-bottom: 0.5rem;
  }
  
  footer .contactIcon {
	color: var(--main-light);
	font-size: 1.5rem; /* Adjust size as needed */
  }
  
  footer .contact-info {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
  }
  
  footer .contact-info span {
	margin-right: 0.75rem; /* Adjust spacing between icon and text */
  }
  
  footer .navi {
	margin-top: 2rem;
  }
  
  footer .navi ul {
	padding-left: 0;
	list-style: none;
  }
  
  footer .navi li {
	margin-bottom: 0.5rem;
	font-size: 14px;
  }
  
  footer .navi .nav-link:hover {
	color: white;
  }
  
  footer .navi .nav-link {
	color: var(--main-light);
	text-decoration: none;
	transition: all ease 0.7s;
  }
  
  footer .lil-text {
	font-size: 14px;
	margin-right: 0; /* Adjust as needed */
  }
  
  footer .form-control {
	margin-bottom: 1rem;
	color: white;
	background: none;
	border-radius: 0;
	font-weight: bold;
	border: none;
	border-bottom: 2px solid white;
  }
  
  footer .form-control:focus {
	color: white;
	box-shadow: none;
	background: none;
	border: none;
	border-bottom: 1.5px solid white;
  }
  
  footer .angas {
	background-color: var(--main-light);
  }
  
  footer .angas:hover {
	background-color: var(--main-text);
  }
  
  .last p {
	font-size: 12px;
	margin-top: 16px;
  }
  
  @media (max-width: 768px) {
	footer .contact-info {
	  flex-direction: column;
	  text-align: center;
	}
  
	footer .contact-info span {
	  margin-bottom: 0.5rem;
	}
  
	.last {
	  flex-direction: column;
	  text-align: center;
	}
  }
  