.hire-va-section {
	text-align: center;
	padding: 4rem 2rem;
	background-color: #f0f0f0;
}

.hire-va-section h2 {
	font-size: 2rem;
	margin-bottom: 1rem;
}

.hire-va-section .highlight {
	color: #2a9df4;
}

.hire-va-section p {
	font-size: 1rem;
	margin-bottom: 2rem;
}

.hire-va-section .steps {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 2rem 0;
}

.hire-va-section .step {
	margin-bottom: 2rem;
	text-align: center;
}

.hire-va-section .stepIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120px;
	width: 120px;
	border-radius: 50%;
	background-color: #1a80fd93;
	position: relative;
	margin-bottom: 1rem;
}

.hire-va-section .stepIcon img {
	height: 60px;
	width: auto;
}

.hire-va-section .numb {
	height: 25px;
	width: 25px;
	color: white;
	background: black;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 10px;
	left: 10px;
	opacity: 0.875;
	font-size: 0.875rem;
}

.hire-va-section .strategy-call-button {
	background-color: #1fcac5;
	color: white;
	padding: 0.75rem 1.5rem;
	border-radius: 50px;
	text-decoration: none;
	display: inline-block;
	margin-top: 2rem;
}

.hire-va-section .strategy-call-button:hover {
	background-color: #17a2b8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.hire-va-section h2 {
		font-size: 1.5rem;
	}

	.hire-va-section p {
		font-size: 0.875rem;
	}

	.hire-va-section .stepIcon {
		height: 100px;
		width: 100px;
	}

	.hire-va-section .stepIcon img {
		height: 50px;
	}

	.hire-va-section .numb {
		height: 20px;
		width: 20px;
		font-size: 0.75rem;
	}
}
