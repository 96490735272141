#hero1 {
	position: relative; /* add this */
	background: url("../../assets/Services/Hero.jpg") no-repeat center center;
	background-size: cover;
	color: white;
	height: calc(100vh - 92px);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	z-index: -3;
  }
  
  #hero1::before {
	/* add this pseudo-element */
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: inherit; /* inherit the background image */
	background-size: cover;
	filter: blur(5px); /* apply the blur effect */
	z-index: -1; /* make sure it's behind the other elements */
  }
  
  #hero1 h2 {
	font-size: 72px;
	color: white;
	font-weight: 800;
	margin-bottom: 1rem;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  #hero1 h2 span {
	color: var(--main-light);
  }
  
  #hero1 p {
	font-size: 18px;
	margin-bottom: 2rem;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
	#hero1 h2 {
	  font-size: 56px;
	}
  }
  
  @media (max-width: 992px) {
	#hero1 h2 {
	  font-size: 48px;
	}
  }
  
  @media (max-width: 768px) {
	#hero1 h2 {
	  font-size: 40px;
	}
  
	#hero1 p {
	  font-size: 16px;
	}
  }
  
  @media (max-width: 576px) {
	#hero1 h2 {
	  font-size: 32px;
	}
  
	#hero1 p {
	  font-size: 14px;
	}
  }
  