#welcome {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/Home/Section1.png") no-repeat center center;
  background-size: cover;
  color: white;
  min-height: calc(100vh - 92px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  text-align: center;
}

#welcome h1 {
  font-size: 4rem;
  font-weight: bolder;
  margin-bottom: 1rem;
}

#welcome h2 {
  font-size: 2.5rem;
  font-weight: lighter;
  margin-bottom: 1rem;
}

#welcome .highlight {
  color: #2edbe2;
}

#welcome .bold {
  font-weight: 700;
}

.btn {
  background-color: #007bff; /* Primary button color */
  color: #fff; /* White text color */
  border-radius: 50px; /* Rounded button */
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease-in-out;
}

.btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Tablets */
@media (max-width: 768px) {
  #welcome h1 {
    font-size: 3rem;
  }

  #welcome h2 {
    font-size: 2rem;
  }
  #welcome {
    padding: 20px;
  }
  .btn {
    padding: 0.5rem 2rem;
  }
}

/* Smartphones */
@media (max-width: 480px) {
  #welcome h1 {
    font-size: 2.5rem;
  }
  #welcome {
    padding: 20px;
  }

  #welcome h2 {
    font-size: 1.5rem;
  }

  .btn {
    padding: 0.5rem 1.5rem;
  }
}
