#outsource {
	background: url("../../../assets/Pricing/picture/pricing1.png") no-repeat center center;
	background-size: cover;
	padding: 5rem 0;
	color: white;
  }
  
  #outsource h1 {
	margin-bottom: 2rem;
	font-size: 2rem;
  }
  
  #outsource h1 span {
	color: #1fcac5;
  }
  
  #outsource .osIcon {
	height: 70px;
	width: auto;
	border-radius: 50%;
	padding: 10px;
	border: 2px solid white;
  }
  
  #outsource .osTexts p {
	font-size: 14px;
	margin: 0;
	padding-right: 1rem;
  }
  
  #outsource .osTexts h5 {
	font-size: 1.25rem;
  }
  
  #outsource .osTexts h6 {
	font-size: 1rem;
	margin-top: 0.5rem;
  }
  
  /* Media Queries */
  @media (max-width: 767px) {
	#outsource h1 {
	  font-size: 1.5rem;
	}
  
	#outsource .osIcon {
	  height: 50px;
	}
  
	#outsource .osTexts h5 {
	  font-size: 1rem;
	}
  
	#outsource .osTexts p {
	  font-size: 12px;
	}
  
	#outsource .osTexts h6 {
	  font-size: 0.875rem;
	}
  }
  