#hero {
  background: url("../../assets/phase2HERO.png") no-repeat center center;
  background-size: cover;
  color: white;
  height: calc(100vh - 92px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  text-align: center; /* Added to center the text on smaller screens */
}

#hero h1 {
  font-size: 30px;
  font-weight: lighter;
  margin-bottom: 1rem;
}

#hero h2 {
  font-size: 74px;
  color: var(--main-text);
  text-shadow: 5px 3px 3px rgba(0, 0, 0, 0.9);
  margin-bottom: 1rem;
}

#hero p {
  font-size: 18px;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  #hero {
    height: auto; /* Let the height adjust based on content for smaller screens */
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #hero h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  #hero h2 {
    font-size: 2.5rem; /* Adjust font size for smaller screens */
  }

  #hero p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  #hero .btn {
    padding: 10px 20px; /* Adjust button padding for smaller screens */
  }
}

@media (max-width: 576px) {
  #hero h1 {
    font-size: 1.25rem; /* Further adjust font size for very small screens */
  }

  #hero h2 {
    font-size: 2rem; /* Further adjust font size for very small screens */
  }

  #hero p {
    font-size: 0.875rem; /* Further adjust font size for very small screens */
  }

  #hero .btn {
    padding: 8px 16px; /* Further adjust button padding for very small screens */
  }
}
