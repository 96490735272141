.stats-container {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  max-width: 1000px;
  padding-inline: 16px;
}

.stats-container .stat {
  background: none;
  padding: 20px;
  text-align: center;
  color: #3b18d0;
}

.stats-container .stat .odometer {
  font-size: 50px;
  font-weight: bold;
  display: inline-block;
}

.stats-container .stat .type {
  font-size: 20px;
}

.odometer.plus {
  position: relative;
}

.odometer.plus::after {
  content: "+";
  position: absolute;
  top: 0;
  right: -16px;
  font-size: 20px;
}

@media (max-width: 700px) {
  .stats-container {
    grid-template-columns: 1fr;
  }
}
