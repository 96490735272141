#companyMV {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../assets/Home/image 26.png") no-repeat center center;
  background-size: cover;
  color: white;
  height: auto; /* Adjusted to fit content */
  padding: 5rem 2rem; /* Increased padding for better spacing */
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-img {
  border-radius: 8px; /* Rounded corners for the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  max-width: 100%; /* Ensures image fits well */
}

.big-text {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.lil-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: justify;
}

@media (min-width: 768px) {
  .big-text {
    font-size: 2.5rem; /* Larger text for medium to larger screens */
  }

  .lil-text {
    font-size: 1.125rem; /* Slightly larger text for better readability */
  }
}
