#grow .bg-primary {
  border-radius: 5px;
}

#grow .ribbon {
  width: 100%;
  height: 100px;
  background: var(--main-color);
  position: absolute;
  margin-top: 120px;
  z-index: -10;
}

#grow .big-text {
  padding-top: 70px;
  text-align: center;
  font-size: 32px;
}
#grow .lil-text {
  margin-left: 30px;
  text-align: justify;
  font-size: 14px;
}
