#expect {
	height: auto; /* Allow height to adjust based on content */
	background: #2d1495;
	padding: 5rem 0; /* Add padding for vertical spacing */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  
  #expect h2 {
	margin-bottom: 2rem;
	color: white;
	font-size: 2.5rem; /* Responsive font size */
  }
  
  #expect .expectIcon {
	height: 80px; /* Adjusted for responsiveness */
	width: 80px; /* Adjusted for responsiveness */
	background: #ddd;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	margin-bottom: 1rem;
  }
  
  #expect h3 {
	color: #333;
	font-size: 2rem; /* Adjusted for responsiveness */
  }
  
  .card-title {
	font-size: 1.25rem; /* Responsive font size */
  }
  
  .card-text {
	font-size: 1rem; /* Responsive font size */
	padding-left: 1rem;
	padding-right: 1rem;
  }
  
  /* Responsive adjustments for larger screens */
  @media (min-width: 992px) {
	#expect h2 {
	  font-size: 3rem;
	}
  
	#expect .expectIcon {
	  height: 100px;
	  width: 100px;
	}
  
	#expect h3 {
	  font-size: 2.5rem;
	}
  
	.card-title {
	  font-size: 1.5rem;
	}
  
	.card-text {
	  font-size: 1.125rem;
	}
  }
  