.accordion-item {
	border: 3px solid rgb(255, 255, 255) !important;
}

.QuestionSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 50px;
}
