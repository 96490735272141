/* Footer Styles */
footer {
  background-color: #343a40;
}

footer h5 {
  margin-bottom: 1rem;
  color: var(--main-light);
}

footer p {
  margin-bottom: 0.5rem;
}

footer .contactIcon {
  color: var(--main-light);
}

footer .navi {
  margin-top: 2rem;
}

footer .navi li {
  list-style: none;
  font-size: 14px;
  margin-bottom: 0.5rem; /* Spacing between items */
}

footer .navi .nav-link {
  color: var(--main-light);
  text-decoration: none;
  transition: none; /* Remove any transition effects */
}

footer .navi .nav-link:hover {
  color: var(--main-light); /* Same color on hover */
}

footer .lil-text {
  font-size: 14px;
  margin-right: 5rem;
}

footer i {
  padding: 3px;
  margin: 3px;
  color: var(--main-light);
}

footer .form-control {
  margin-bottom: 1rem;
  color: white;
  background: none;
  border-radius: 0;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid white;
}

footer .form-control:focus {
  color: white;
  box-shadow: none;
  background: none;
  border: none;
  border-bottom: 1.5px solid white;
}

footer .angas {
  background-color: var(--main-light);
}

footer .angas:hover {
  background-color: var(--main-text);
}

.last p {
  font-size: 12px;
  margin-top: 16px;
}
