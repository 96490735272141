#book {
	background: var(--main-color);
	height: auto;
	min-height: 48vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

#book .container {
	width: 100%;
	height: 100%;
	max-width: 1200px;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.25),
			rgba(255, 255, 255, 0.25)
		),
		url("../../assets/Services/Lastly.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

#book .container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--main-color-focus);
	opacity: 0.5;
	pointer-events: none;
}

#book .content {
	width: 100%;
	max-width: 600px;
	text-align: center;
	padding: 20px;
}

#book .content h1 {
	font-size: 32px;
	font-weight: bolder;
	color: white;
	margin-bottom: 20px;
}

#book .content p {
	letter-spacing: 2px;
	font-size: 16px;
	color: white;
	font-weight: lighter;
	margin-bottom: 30px;
}

#book .bookBtn {
	background-color: var(--main-color) !important;
	color: white !important;
	border: none;
	outline: none;
}

#book .bookBtn:hover {
	background-color: var(--main-color-hover) !important;
	color: white;
}

#book .bookBtn:focus {
	background-color: var(--main-color-focus) !important;
	color: white !important;
	box-shadow: none;
	border: none;
	outline: none;
}

#book .btn,
#book h1,
#book p {
	position: relative;
	z-index: 1;
}

@media (min-width: 768px) {
	#book .content h1 {
		font-size: 40px;
	}
}

@media (min-width: 992px) {
	#book .content h1 {
		font-size: 50px;
	}
}
