#adminUI .nav-btnx {
	transition: all ease 0.3s;
	background: none !important;
	border: none !important;
	outline: none !important;
	padding-left: px;
	margin-bottom: 5px;
	padding: 5px 0 5px 15px;
	text-align: start;
}

#adminUI .nav-btnx:hover {
	color: var(--main-color-hover);
	background: #ccc !important;
}

#adminUI .nav-btnx.active {
	color: #eee;
	background: #777 !important;
}

#adminUI .col-9 {
	background: #b1d4e3;
	border-radius: 15px;
	padding: 20px;
}
