/* IndustryNav.css */

.nav-pills .nav-link {
  border-radius: 8px;
  background-color: #f0f2f5;
  color: #333;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  padding: 10px 15px;
  text-align: left;
}

.nav-pills .nav-link.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  transform: scale(1.05);
}

.nav-pills .nav-link:hover {
  background-color: #007bff;
  color: white;
}

.tab-content .tab-pane {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tab-content .tab-pane.active {
  display: block;
  opacity: 1;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tab-content h4 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #007bff;
}

.tab-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.tab-content a {
  color: #007bff;
  text-decoration: none;
}

.tab-content a:hover {
  text-decoration: underline;
}

.tab-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;
}

.tab-content ul li {
  margin-bottom: 10px;
}

.tab-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.text-center a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.text-center a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* New styles for improved design */
.icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-color: #007bff;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 30px;
}

.tab-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .nav-pills .nav-link {
    text-align: center;
  }
}
