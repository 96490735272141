.btn {
	background-color: var(--main-color) !important;
	color: white !important;
	border: none;
	outline: none;
}

.btn:hover {
	background-color: var(--main-color-hover) !important;
	color: white;
}

.btn:focus {
	background-color: var(--main-color-focus) !important;
	color: white !important;
	box-shadow: none;
	border: none;
	outline: none;
}

#roles .btnx {
	background-color: var(--main-light) !important;
	color: white !important;
	border: none;
	outline: none;
}

#roles .btnx:hover {
	background-color: var(--main-text) !important;
	color: white;
}

#roles .btnx:focus {
	background-color: var(--main-text) !important;
	color: white !important;
	box-shadow: none;
	border: none;
	outline: none;
}
