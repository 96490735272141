#hero2 {
	background: url("../../assets/PricingHero.png") no-repeat center center;
	background-size: cover;
	color: white;
	min-height: 100vh; /* Minimum height of full viewport height */
	padding: 0 15px; /* Adjusted padding */
	box-sizing: border-box; /* Ensure padding does not affect height */
	display: flex;
	justify-content: center; /* Center content horizontally */
	align-items: center; /* Center content vertically */
  }
  
  #hero2 h1 {
	font-size: 2rem; /* Responsive font size for smaller screens */
	font-weight: lighter;
	margin-bottom: 1rem;
  }
  
  #hero2 h2 {
	font-size: 3rem; /* Responsive font size for smaller screens */
	font-weight: bolder;
	color: var(--main-text);
	text-shadow: 5px 3px 3px rgba(0, 0, 0, 0.9);
	margin-bottom: 1rem;
  }
  
  #hero2 p {
	font-size: 1rem; /* Responsive font size for smaller screens */
	margin-bottom: 2rem;
  }
  
  @media (min-width: 768px) {
	#hero2 {
	  padding: 20px; /* Adjust padding for larger screens */
	}
  
	#hero2 h1 {
	  font-size: 2.5rem;
	}
  
	#hero2 h2 {
	  font-size: 4rem;
	}
  
	#hero2 p {
	  font-size: 1.25rem;
	}
  }
  
  @media (min-width: 992px) {
	#hero2 h1 {
	  font-size: 3rem;
	}
  
	#hero2 h2 {
	  font-size: 5rem;
	}
  
	#hero2 p {
	  font-size: 1.5rem;
	}
  }
  