#roles .card {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 14rem !important;
  margin-bottom: 1rem;
  transition: all ease 0.3s;
  background-color: #f8f9fa; /* Light background color for cards */
  border-radius: 8px; /* Slightly rounded corners */
}

#roles .card:hover {
  transform: scale(1.05);
  background-color: #e9ecef; /* Slightly darker background on hover */
}

#roles .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem; /* Add padding for better spacing */
}

#roles .rolex img {
  height: 70px;
  object-fit: contain; /* Maintain aspect ratio of icons */
  margin-bottom: 1rem; /* Add spacing below the icon */
}

#roles .card-title {
  font-weight: 700;
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #333; /* Dark text color for better readability */
}

#roles .btn-text p {
  max-width: 25rem;
}

.role-text {
  font-weight: 500;
}

.role-text span {
  color: var(--main-light);
}
