/* Apply a subtle background color to the entire page */
body {
  background-color: #f7f7f7;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Improve paragraph styling */
p {
  line-height: 1.8; /* Increase line height for better readability */
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}
