#blogSection {
	height: auto;
	padding: 2rem 0;
	background: linear-gradient(rgb(156, 156, 156), rgb(156, 156, 156)),
	  url("../../assets/Home/Home1/bloghome_bg.png") no-repeat center center /
		cover;
	background-blend-mode: overlay;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  #blogSection .card {
	transition: 0.3s ease-in-out;
  }
  
  #blogSection .card:hover {
	transform: scale(1.025);
	box-shadow: 0 0 30px white;
  }
  
  #blogSection .card-body {
	height: 112px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
  }
  
  @media (max-width: 768px) {
	#blogSection .card-body {
	  height: auto;
	  padding: 1rem;
	}
  
	#blogSection .card-title {
	  font-size: 1rem;
	}
  }
  
  @media (max-width: 576px) {
	#blogSection {
	  padding: 1rem 0;
	}
  
	#blogSection .card-title {
	  font-size: 0.875rem;
	}
  }
  